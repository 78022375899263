// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAmoI3UEOg1PAycJNzAeM82I--ywucfwn0",
    authDomain: "times-craft-activity.firebaseapp.com",
    projectId: "times-craft-activity",
    storageBucket: "times-craft-activity.appspot.com",
    messagingSenderId: "303867986676",
    appId: "1:303867986676:web:4b855e0bd302c8d8b954a1",
    measurementId: "G-DN25EYQXYZ"
};

const app = initializeApp(firebaseConfig);

export function initFirebse() {
    // Initialize Firebase
    getAnalytics(app);
    // getPerformance(app);
}

export const auth = getAuth(app);