
import { isWeekend, parseJSON } from "date-fns"
import Holidays, { HolidaysTypes } from "date-holidays"
import { DateUtils } from '../../commons/DateUtils';

export enum CountryCode {
    FR = "FR",
    US = "US",
    UK = "UK"
}

export function isHoliday(countryCode: CountryCode, date: Date | string): boolean {
    const holiday: false | HolidaysTypes.Holiday[] = new Holidays(countryCode).isHoliday(date)
    if (holiday instanceof Boolean) {
        return holiday as boolean
    }

    return (holiday as []).length > 0
}

export function isWeekEnd(date: Date | string): boolean {
    return isWeekend(parseJSON(date))
}

export function isNotWorkDay(countryCode: CountryCode, date: Date | string): boolean {
    return (isWeekend(parseJSON(date)) || isHoliday(countryCode, date))
}

export function checkIfDayNeedToBeDisabled(countryCode: CountryCode, dateA: Date, dateB: Date): boolean {
    return (isNotWorkDay(countryCode, dateA) || !DateUtils.isTheSameMonth(dateA, dateB))
}
