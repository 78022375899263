import { DateUtils } from "../../commons/DateUtils";
import { CountryCode, isHoliday, isNotWorkDay } from "../holidays/HolidayServices";

export interface WorkDayCalendarType {
    date: Date;
    dayOfWeekFormatted: string;
    am: boolean;
    pm: boolean;
    isWorkinDay: boolean;
    isHoliday: boolean;
    startDate?: Date;
    endDate?: Date;
    vacation?: boolean;
}

export function validateCraDay(cra: WorkDayCalendarType): boolean {
    return cra.am || cra.pm
}

export function hasDateAlreadyExist(arr: WorkDayCalendarType[] | null | undefined, value: Date): boolean {
    if (!arr) return false;
    return arr?.filter(Boolean).some(obj =>
        obj?.date?.getTime() === value?.getTime());
}

export function generateCalendarDays(countryCode: CountryCode, lang: string, currentDate: Date, startDate: Date, endDate: Date): Array<WorkDayCalendarType> {
    const calendarDays: WorkDayCalendarType[] = [];
    for (let day = startDate.getDate(); day <= endDate.getDate(); day++) {
        const currentDay = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            day
        );

        const formattedDate = DateUtils.weekDayFormatted(
            currentDay,
            lang
        );
        calendarDays.push({
            date: currentDay,
            dayOfWeekFormatted: formattedDate,
            am: !isNotWorkDay(countryCode, currentDay),
            pm: !isNotWorkDay(countryCode, currentDay),
            isWorkinDay: isNotWorkDay(countryCode, currentDay),
            isHoliday: isHoliday(countryCode, currentDay),
            startDate: startDate,
            endDate: endDate,
        });
    }

    return calendarDays
}