import { CountryCode } from "../../services/holidays/HolidayServices";
import { ActionTypeValue, AppAction } from "./craActions";

export interface AppState {
  language: string;
  countryCode: CountryCode;
}

// Define your initial state
export const initialState: AppState = {
  language: 'en',
  countryCode: CountryCode.US // Default language
};

export const reducerAppState = (state = initialState, action: AppAction): AppState => {
  switch (action.type) {
    case ActionTypeValue.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case ActionTypeValue.SET_COUNTRY_CODE:
      return { ...state, countryCode: action.payload };
    default:
      return state;
  }
};
