import { combineReducers } from 'redux';
import { reducerAppState } from './cra/craStore';
import authReducer from './auth/authStore';

export const rootReducer = combineReducers({
  appState: reducerAppState,
  auth: authReducer
})

export type RootReducer = ReturnType<typeof rootReducer>





