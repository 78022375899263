import { getCountryCode } from "../../services/countries/CountriesServices";

export enum ActionTypeValue {
    SET_LANGUAGE = 'SET_LANGUAGE',
    SET_COUNTRY_CODE = 'SET_COUNTRY_CODE'
}

export interface AppAction {
    type: ActionTypeValue;
    payload: any;
}

export const setLanguageAction = (language: string) => {
    return {
        type: ActionTypeValue.SET_LANGUAGE,
        payload: language,
    }
}

export const setCountryCodeAction = (language: string) => {
    const countryCode = getCountryCode(language);
    return {
        type: ActionTypeValue.SET_COUNTRY_CODE,
        payload: countryCode,
    }
}
