import { AuthAction } from "./authActions";

export interface AuthState {
    isLoggedIn: boolean;
    authToken?: string;
    userId?: string;
    name?: string;
    email?: string;
};

export const defaultAuthState: AuthState = {
    isLoggedIn: false,
};


const authReducer = (state: AuthState = defaultAuthState, action: AuthAction) => {
    // user successfully authenticated
    if (action.type === "LOG_IN") {
        localStorage.setItem("user", JSON.stringify(action.payload));
        return {
            ...state,
            isLoggedIn: true,
            authToken: action.payload.authToken,
            userId: action.payload.userId,
            name: action.payload.name,
            email: action.payload.email,
        };
    }

    // log out user
    if (action.type === "LOG_OUT") {
        localStorage.removeItem("user");
        return defaultAuthState;
    }

    return defaultAuthState;
};

export default authReducer;