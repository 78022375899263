import i18n from "i18next";
import { initReactI18next } from 'react-i18next'; // the translations
import Backend from 'i18next-http-backend';

i18n.use(Backend).use(initReactI18next).init({
  lng: navigator.language,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;