import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { getCountryCodeFromTimeZone } from "./services/countries/CountriesServices";
import {
  setCountryCodeAction,
  setLanguageAction,
} from "./redux/cra/craActions";
import { getCurrentLanguageOfBrowser } from "./commons/LangaugeUtils";
import { rootReducer } from "./redux/storeApp";

export const store = configureStore({ reducer: rootReducer });

store.dispatch(setLanguageAction(getCurrentLanguageOfBrowser()));

const countryCode = getCountryCodeFromTimeZone(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
store.dispatch(setCountryCodeAction(countryCode));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
