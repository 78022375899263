// components/Layout.tsx
import React, { ReactNode } from 'react';
import Header from '../header/Header';

interface LayoutProps {
  children:ReactNode;
  hideCountrySelection?: boolean;
  hideLanguageSelection?: boolean;
}

const PageWrapped: React.FC<LayoutProps> = ({ children,hideCountrySelection,
  hideLanguageSelection }) => {
  return (
    <div>
      <Header hideCountrySelection={hideCountrySelection} hideLanguageSelection={hideLanguageSelection}/>
      <main>{children}</main>
    </div>
  );
};

export default PageWrapped;