// Auth.tsx

import React, { useState } from "react";
import "./Login.css";
import {
  loginWithEmailAndPassword,
  signInWithApple,
  signInWithGithub,
  signInWithGoogle,
  signInWithMicrosoft,
} from "../Services/AuthServices";
import { useTranslation } from "react-i18next";
import { logger } from "../../commons/Logger";

import google_logo from "../../ressources/logos/google_logo.png";
import microsoft_logo from "../../ressources/logos/microsoft_logo.png";
import github_logo from "../../ressources/logos/github_logo.png";
import logo512 from "../../ressources/logos/logo512.png";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = async () => {
    try {
      await loginWithEmailAndPassword(email, password);
      // Handle successful authentication
    } catch (error) {
      logger.errors("Authentication failed: " + error);
      // Handle authentication error
    }
  };

  return (
    <div className="auth-container">
      <div className="logo-container">
        <img src={logo512} alt="logo" />
      </div>
      <div className="container_form">
        <div className="auth-box">
          <h1 className="auth-title">{t("welcome_again")}</h1>
          <form>
            <input
              id="email"
              placeholder={t("email")}
              type="email"
              value={email}
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              placeholder={t("password")}
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="signin-button" onClick={handleSignIn}>
              {t("login")}
            </button>

            <p className="create_account">
              {t("no_account")}
              <a
                className="create_account_to_link"
                href="/signup"
                aria-label=""
              >
                {t("signup")}
              </a>
            </p>
            <button
              onClick={signInWithGoogle}
              className="social-connect-button"
            >
              <img src={google_logo} alt="" />
              <span>{t("continue_with")} Google</span>
            </button>
            <button
              type="button"
              onClick={signInWithMicrosoft}
              className="social-connect-button"
            >
              <img src={microsoft_logo} alt="" />
              <span>{t("continue_with")} Microsoft</span>
            </button>
            <button
              type="button"
              onClick={signInWithGithub}
              className="social-connect-button"
            >
              <img src={github_logo} alt="" />
              <span>{t("continue_with")} Github</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
