import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import WorkActivityReports from "./report/WorkActivityReports";
import WorkDaysCalendar from "./mycra/WorkDaysCalendar";
import { useEffect, useState } from "react";
import NotFound from "./notfound/NotFound";
import Login from "./auth/Login/Login";
import { initFirebse } from "./firebase/FirebaseWorker";
import { useSelector } from "react-redux";
import { RootReducer } from "./redux/storeApp";
import SignUp from "./auth/SignUp/SignUp";

function App() {
  useEffect(() => {
    initFirebse();
  }, []);

  const isConnected = useSelector(
    (state: RootReducer) => state.auth.isLoggedIn
  );

  const [isDarkModen] = useState(false);
  return (
    <div className={isDarkModen ? "darkStyle" : ""}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<WorkDaysCalendar />} />
          <Route path="/report" element={<WorkActivityReports />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/auth" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
