import { Locale, format, isSameMonth } from "date-fns";
import enUS from 'date-fns/locale/en-US'; // Import the locale
import frFR from 'date-fns/locale/fr'; // Import the locale

export enum DateFormats {
    MM_YY = 'MMMM yyyy',
    DD_WEEK = "EEE d"
}

export class DateUtils {

    static weekDayFormatted(date: Date, _locale: string): string {
        const localeLang = generateLocaleFromLang(_locale);
        const formattedDate = format(date, DateFormats.DD_WEEK, { locale: localeLang });
        return formattedDate.toLocaleUpperCase()
    }


    static dateFromString(date: string): Date {
        return new Date(date);
    }

    static dateStringFormatted(date: Date, _locale: string, _format: string = DateFormats.MM_YY): string {
        const localeLang = generateLocaleFromLang(_locale);
        const formattedDate = format(date, _format, { locale: localeLang });
        return formattedDate.toLocaleUpperCase()
    }

    static isTheSameMonth(a: Date, b: Date): boolean {
        return isSameMonth(a, b);
    }

    static previousMonth(date: Date = new Date()): Date {
        date.setMonth(date.getMonth());
        return date;
    }

    static currentMonth(date: Date = new Date()): Date {
        const nextMonthDate = new Date(date);
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
        nextMonthDate.setDate(0);
        return nextMonthDate;
    }

    static firstDayOfTheMonth(date: Date = new Date()): Date {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay;
    }

    static lastDayOfTheMonth(date: Date = new Date()): Date {
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return lastDay;
    }
}


function generateLocaleFromLang(lang: string): Locale {
    if (lang?.toLocaleLowerCase() === 'fr-FR' || lang?.toLowerCase() === 'fr')
        return frFR;

    return enUS;
}
