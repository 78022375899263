
import { StyleSheet } from '@react-pdf/renderer';

export const StyleSheetPdf = StyleSheet.create({

    generatePDF: {
        borderRadius: 4,
        backgroundColor: "#6A0DAD",
        color: "#fff",
        padding: 10,
        fontWeight: "bold",
        marginBottom: 20
    },

});

