import moment from "moment-timezone";
import { IsoCountries, reverseItems } from "../../commons/CountriesList";
import { CountryCode } from "../holidays/HolidayServices";

export function getCountryFromTimeZone(userTimeZone: string): any {
    // Get a list of countries from moment-timezone
    const countries = moment.tz.countries();

    // Iterate through the countries and check if the time zone is associated with any country
    for (const country of countries) {
        const timeZones = moment.tz.zonesForCountry(country);

        if (timeZones.includes(userTimeZone)) {
            // Use Intl.DisplayNames to get the full country name
            const countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(country);
            return countryName || "US";
        }
    }

    // Return the original time zone if no matching country is found
    return userTimeZone;
}

export function getCountryCodeFromTimeZone(userTimeZone: string): string {
    const countryName = getCountryFromTimeZone(userTimeZone);
    const reversedItems = reverseItems(IsoCountries);
    return reversedItems.get(countryName) || "US";
}


export function getCountryCode(code: string): CountryCode {
    switch (code?.toLowerCase()) {
        case "us":
            return CountryCode.US
        case "fr":
            return CountryCode.FR
    }
    return CountryCode.US
}


export function getCoundryCodeString(code: CountryCode): string {
    switch (code) {
        case CountryCode.US:
            return "us"
        case CountryCode.FR:
            return "fr";
    }
    return CountryCode.US
}