import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import Alert from "../../components/Alert";
import { StyleSheetPdf } from "./PDFGeneratorStyle";
import { t } from "i18next";

interface HTMLToPDFProps {
  htmlContent: JSX.Element;
  fileName?: string;
}

export default function PDFGenerator(props: HTMLToPDFProps) {
  const pdfRef = React.useRef<HTMLDivElement>(null);
  const generatePDF = () => {
    const input = pdfRef.current;
    if (input === null) return <Alert message="Error: pdfRef is null" />;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const pdfWith = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWith / imgWidth, pdfHeight / imgHeight) + 0.01;
      const imgX = (pdfWith - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("cra_report_" + props.fileName + ".pdf");
    });
  };

  return (
    <div>
      <div ref={pdfRef}>{props.htmlContent}</div>
      <div className="p-3 text-center">
        <button
          style={StyleSheetPdf.generatePDF}
          onClick={() => {
            generatePDF();
          }}
        >
          {t("generate_pdf")}
        </button>
      </div>
    </div>
  );
}
