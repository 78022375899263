interface LoggerInterface {
    infos(obj: any): void
    errors(obj: any): void
}

class LoggerImplement implements LoggerInterface {
    infos(obj: any): void {
        console.info(obj)
    }

    errors(obj: any): void {
        console.error(obj)
    }
}

export const logger = new LoggerImplement()