import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./styles/WorkDaysCalendar.css";
import { useTranslation } from "react-i18next";
import { DateUtils } from "../commons/DateUtils";
import {
  WorkDayCalendarType,
  generateCalendarDays,
} from "../services/cra/MyCraServices";
import pastButton from "../ressources/icons/past-button.png";
import nextButton from "../ressources/icons/next-button.png";
import { useSelector } from "react-redux";
import PageWrapped from "../components/PageWrapped";
import { RootReducer } from "../redux/storeApp";

const WorkDaysCalendar: React.FC = () => {
  const language = useSelector((state: RootReducer) => state.appState.language);
  const countryCode = useSelector(
    (state: RootReducer) => state.appState.countryCode
  );

  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const navigate = useNavigate();
  const [days, setDays] = useState<WorkDayCalendarType[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    generateCalendar();
  }, [currentDate, currentDate.getMonth, countryCode, language]);

  const generateCalendar = () => {
    const startDateVar = DateUtils.firstDayOfTheMonth(currentDate);
    setStartDate(startDateVar);

    const endDateVar = DateUtils.lastDayOfTheMonth(currentDate);
    setEndDate(endDateVar);
    const calendarDays = generateCalendarDays(
      countryCode,
      language,
      currentDate,
      startDateVar,
      endDateVar
    );

    setDays(calendarDays);
  };

  const handleGeneratePdf = () => {
    navigate("/report", {
      state: {
        selectedDays: days,
        startDate: startDate,
        endDate: endDate,
        period: currentDate,
      },
    });
  };

  const toggleVacation = (index: number) => {
    const updatedDays = [...days];
    updatedDays[index].vacation = !updatedDays[index].vacation;
    updatedDays[index].am = false;
    updatedDays[index].pm = false;
    setDays(updatedDays);
  };

  const toggleAM = (index: number) => {
    const updatedDays = [...days];
    updatedDays[index].am = !updatedDays[index].am;
    setDays(updatedDays);
  };

  const togglePM = (index: number) => {
    const updatedDays = [...days];
    updatedDays[index].pm = !updatedDays[index].pm;
    setDays(updatedDays);
  };

  function nextMonth(): void {
    const date = new Date(currentDate);
    date.setMonth(date.getMonth() + 1);
    setCurrentDate(date);
  }

  function pastMonth(): void {
    const date = new Date(currentDate);
    date.setMonth(date.getMonth() - 1);
    setCurrentDate(date);
  }

  const renderDatePickers = () => {
    return (
      <div className="div-navigate-months">
        <button className="past-button">
          <img src={pastButton} alt="past" onClick={pastMonth} />
        </button>
        <p style={{ color: "#0066FF" }}>
          {DateUtils.dateStringFormatted(currentDate, language)}
        </p>
        <button className="next-button">
          <img src={nextButton} alt="past" onClick={nextMonth} />
        </button>
      </div>
    );
  };

  function renderTableOfDays(): React.ReactNode {
    return days.map((day, index) => (
      <tr key={index}>
        <td>{day.dayOfWeekFormatted}</td>
        <td>
          <input
            placeholder={t("vacation")}
            disabled={day.isWorkinDay}
            type="checkbox"
            className="calendar-checkbox"
            checked={day.vacation}
            onChange={() => toggleVacation(index)}
          />
        </td>
        <td>
          <input
            placeholder="AM"
            disabled={day.isWorkinDay || day.vacation}
            type="checkbox"
            className="calendar-checkbox"
            checked={day.am}
            onChange={() => toggleAM(index)}
          />
        </td>
        <td>
          <input
            placeholder="PM"
            type="checkbox"
            disabled={day.isWorkinDay || day.vacation}
            className="calendar-checkbox"
            checked={day.pm}
            onChange={() => togglePM(index)}
          />
        </td>
      </tr>
    ));
  }

  return (
    <PageWrapped>
      <div className="calendar-container">
        <h2 className="text-2xl font-bold text-center">
          {renderDatePickers()}
        </h2>
        <table className="calendar-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>{t("vacation")}</th>
              <th>AM</th>
              <th>PM</th>
            </tr>
          </thead>
          <tbody>{renderTableOfDays()}</tbody>
        </table>
        <div className="divFooter">
          <button className="calendar-button" onClick={handleGeneratePdf}>
            {t("generate_cra_report")}
          </button>
        </div>
      </div>
    </PageWrapped>
  );
};

export default WorkDaysCalendar;
