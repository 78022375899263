import React, { useState } from "react";
import { StyleSheet } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateUtils } from "../commons/DateUtils";
import {
  WorkDayCalendarType,
  validateCraDay,
} from "../services/cra/MyCraServices";
import { isNotWorkDay } from "../services/holidays/HolidayServices";
import { colors } from "../commons/Colors";
import "./WorkActivityReport.css";
import { useSelector } from "react-redux";
import PDFGenerator from "./PdfGenerator/PDFGenerator";
import PageWrapped from "../components/PageWrapped";
import { AppState } from "../redux/cra/craStore";
interface WorkActivityReportsProps {
  period: Date;
  selectedDays: Array<WorkDayCalendarType>;
}

const styles = StyleSheet.create({
  containerStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between", // Align children to left and right
    width: "70%",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "10px",
  },

  leftDivStyle: {
    width: "220px",
    height: "200px",
    border: "1px solid black",
    padding: "10px",
    textAlign: "center",
  },

  rightDivStyle: {
    width: "220px",
    height: "200px",
    border: "1px solid black",
    padding: "10px",
    textAlign: "center",
  },
  divInfo: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    margin: "auto",
    alignContent: "flex-start",
    alignItems: "flex-start",
  },
  page: {
    flexDirection: "column",
    padding: 20,
  },
  text: {
    fontSize: 12,
  },
  header: {
    marginTop: 10,
    marginLeft: 20,
    fontFamily: "Helvetica",
    fontSize: 18,
    fontWeight: "bold",
    color: "#0066FF",
  },
  heading: {
    fontSize: 24,
    textDecoration: "underline",
  },
  subheading: {
    fontSize: 16,
  },
  tableContainer: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    margin: "auto",
    alignContent: "center",
    textAlign: "center",
  },
  tableHeader: {
    backgroundColor: colors.purple,
    fontWeight: "bold",
    flex: 1,
    border: "1px solid #ccc",
    color: "white",
    fontSize: 18,
    alignItems: "center",
  },
  tableRow: {
    padding: 5,
    textAlign: "center",
    flex: 1,
    border: "1px solid #ccc",
  },
  tableRowNoWorkingDay: {
    padding: 5,
    textAlign: "center",
    flex: 1,
    border: "1px solid #ccc",
    backgroundColor: "#e0e0e0",
  },

  tableRowHolidDay: {
    padding: 5,
    textAlign: "center",
    flex: 1,
    border: "1px solid #ccc",
    backgroundColor: "#27005D",
    color: "white",
    fontWeight: "bold",
  },

  tableRowVacation: {
    padding: 5,
    textAlign: "center",
    flex: 1,
    border: "1px solid #ccc",
    backgroundColor: "#003049",
    color: "white",
    fontWeight: "bold",
  },
});

const WorkActivityReports = () => {
  const state = useSelector((state: AppState) => state);
  const [countryCode] = useState(state.countryCode);
  const location = useLocation();
  const { t } = useTranslation();
  if (!location.state) return <div>Invalid Report</div>;

  const { period, selectedDays } = location.state as WorkActivityReportsProps;
  if (!selectedDays || selectedDays.length === 0 || !period)
    return <div>{t("invalid_report")}</div>;

  const periodValue = DateUtils.dateStringFormatted(period, state.language);

  function renderDayStyle(day: WorkDayCalendarType) {
    if (day.isHoliday) {
      return styles.tableRowHolidDay;
    }
    if (day.vacation) {
      return styles.tableRowVacation;
    }
    if (!day.am && !day.pm) {
      return styles.tableRowNoWorkingDay;
    }

    return styles.tableRow;
  }

  function renderAM(day: WorkDayCalendarType) {
    if (day.isHoliday) {
      return styles.tableRowHolidDay;
    }
    if (day.vacation) {
      return styles.tableRowVacation;
    }
    if (day.am) {
      return styles.tableRow;
    }
    return styles.tableRowNoWorkingDay;
  }

  function renderPM(day: WorkDayCalendarType) {
    if (day.isHoliday) {
      return styles.tableRowHolidDay;
    }
    if (day.vacation) {
      return styles.tableRowVacation;
    }
    if (day.pm) {
      return styles.tableRow;
    }
    return styles.tableRowNoWorkingDay;
  }

  function renderVacation(day: WorkDayCalendarType) {
    if (day.isHoliday) {
      return styles.tableRowHolidDay;
    }

    if (day.vacation) {
      return styles.tableRowVacation;
    }

    if (!validateCraDay(day)) {
      return styles.tableRowNoWorkingDay;
    }
    return styles.tableRow;
  }

  function renderHoliday(day: WorkDayCalendarType) {
    if (day.isHoliday) {
      return styles.tableRowHolidDay;
    }
    if (day.vacation) {
      return styles.tableRowVacation;
    }
    if (day.pm) {
      return styles.tableRow;
    }
    return styles.tableRowNoWorkingDay;
  }

  function renderMonth(): React.ReactNode {
    return <h2 style={styles.header}> {periodValue}</h2>;
  }

  function renderEstimatedWorkingDays(): React.ReactNode {
    return (
      <h3 style={{ margin: 10, marginLeft: 20, fontSize: 18 }}>
        {t("estimated_work_days", {
          estimatedDays: selectedDays.filter(
            (e) => !isNotWorkDay(countryCode, e.date)
          ).length,
        })}
      </h3>
    );
  }

  function renderWorkingDays(): React.ReactNode {
    return (
      <h3 style={{ marginLeft: 20, fontWeight: "bold", fontSize: 22 }}>
        {t("work_days", {
          days: selectedDays.filter((e) => validateCraDay(e)).length,
        })}
      </h3>
    );
  }

  function reportContent(): JSX.Element {
    return (
      <div>
        <div style={styles.divInfo}>
          {renderMonth()}
          {renderEstimatedWorkingDays()}
          {renderWorkingDays()}
        </div>
        <div style={styles.page}>
          <div style={styles.tableContainer}>
            <div style={styles.tableHeader}>
              <div className="container-table-header">
                <span>{t("days")}</span>
              </div>
            </div>
            <div style={styles.tableHeader}>
              <div className="container-table-header">
                <span>{t("vacation")}</span>
              </div>
            </div>
            <div style={styles.tableHeader}>
              <div className="container-table-header">
                <span>AM</span>
              </div>
            </div>

            <div style={styles.tableHeader}>
              <div className="container-table-header">
                <span>PM</span>
              </div>
            </div>
            <div style={styles.tableHeader}>
              <div className="container-table-header">
                <span>{t("public_holiday")}</span>
              </div>
            </div>
          </div>
          {selectedDays.map((day, index) => (
            <div key={index} style={styles.tableContainer}>
              {
                <div style={renderDayStyle(day)}>
                  <span>
                    {DateUtils.weekDayFormatted(day.date, state.language)}
                  </span>
                </div>
              }

              <div style={renderVacation(day)}>
                <span>{day.vacation ? "Yes" : "No"}</span>
              </div>

              <div style={renderAM(day)}>
                <span>{day.am ? "Yes" : "No"}</span>
              </div>
              <div style={renderPM(day)}>
                <span>{day.pm ? "Yes" : "No"}</span>
              </div>
              <div style={renderHoliday(day)}>
                <span>{day.isHoliday ? "Yes" : "No"}</span>
              </div>
            </div>
          ))}
        </div>

        <div style={styles.containerStyle}>
          <div style={styles.leftDivStyle}>{t("client_signature")}</div>
          <div style={styles.rightDivStyle}>{t("consultant_signature")}</div>
        </div>
      </div>
    );
  }

  return (
    <PageWrapped hideCountrySelection={true}>
      <PDFGenerator htmlContent={reportContent()} fileName={periodValue} />
    </PageWrapped>
  );
};

export default WorkActivityReports;
