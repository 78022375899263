import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../ressources/translations/i18n";
import "./Header.css";
import {
  setCountryCodeAction,
  setLanguageAction,
} from "../redux/cra/craActions";
import { useSelector, useDispatch } from "react-redux";
import { getCoundryCodeString } from "../services/countries/CountriesServices";
import { RootReducer } from "../redux/storeApp";

export interface HeaderProps {
  hideCountrySelection?: boolean;
  hideLanguageSelection?: boolean;
}
const Header = (props: HeaderProps) => {
  const { t } = useTranslation();
  const language = useSelector((state: RootReducer) => state.appState.language);
  const countryCode = useSelector(
    (state: RootReducer) => state.appState.countryCode
  );

  const dispatch = useDispatch();

  // State to manage selected language and country
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language); // Default language
  const [selectedCountry, setSelectedCountry] = useState<string>(
    getCoundryCodeString(countryCode)
  );

  function changeLanguage(language: string) {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  }

  // Handle language selection
  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    changeLanguage(event.target.value);
    dispatch(setLanguageAction(event.target.value));
  };

  // Handle country selection
  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    dispatch(setCountryCodeAction(event.target.value));
  };

  return (
    <div className="header">
      <div className="div-container">
        <div className="language-select" hidden={props.hideLanguageSelection}>
          <label htmlFor="languageSelect">{t("language")} :</label>
          <select
            id="languageSelect"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            <option value="en">{t("english")}</option>
            <option value="fr">{t("french")}</option>
          </select>
        </div>
        {/* <div className="country-select" hidden={props.hideCountrySelection}>
          <label htmlFor="countrySelect">{t("country")}</label>
          <select
            id="countrySelect"
            value={selectedCountry}
            onChange={handleCountryChange}
          >
            <option value="us">United States</option>
            <option value="fr">France</option>
          </select>
        </div> */}
      </div>
      {/* <button className="sign-in">{t("signin")}</button> */}
    </div>
  );
};

export default Header;
