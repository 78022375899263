// SignUp.tsx
import React, { useState } from "react";
import { logger } from "../../commons/Logger";

const SignUp: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSignUp = () => {
    if (true) {
      logger.infos("Signed up successfully");
      // Redirect or perform other actions upon successful sign-up
    } else {
      logger.infos("Username already taken");
      // Display an error message to the user
    }
  };

  return (
    <div>
      <h2>Sign Up</h2>
      <label>
        Username:
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </label>
      <br />
      <label>
        Password:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
      <br />
      <button onClick={handleSignUp}>Sign Up</button>
    </div>
  );
};

export default SignUp;
