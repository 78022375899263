import { signInWithPopup, GoogleAuthProvider, OAuthProvider, GithubAuthProvider, signInWithEmailAndPassword, UserCredential, signInWithRedirect, signOut, revokeAccessToken } from "firebase/auth";
import { logger } from "../../commons/Logger";
import { auth } from "../../firebase/FirebaseWorker";

const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');
const appleProvider = new OAuthProvider('apple.com');
const githubProvider = new GithubAuthProvider();

export const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
        const user: UserCredential = await signInWithEmailAndPassword(auth, email, password);
        logger.infos(user);
    } catch (error) {
        logger.errors('Email authentication failed:' + error);
        // Handle authentication error
    }
};
export const signInWithGoogle = async () => {
    try {
        signInWithPopup(auth, googleProvider).then((user) => {
            logger.infos(user);
        }).catch((error) => {
            logger.errors(error);
        });
        //logger.infos(user);
        // Handle successful authentication
    } catch (error) {
        logger.errors('Google authentication failed:' + error);
        // Handle authentication error
    }
};

export const signInWithMicrosoft = async () => {
    try {
        const user: UserCredential = await signInWithPopup(auth, microsoftProvider);
        logger.infos(user);
    } catch (error) {
        logger.errors('Microsoft authentication failed:' + error);
        // Handle authentication error
    }
};

export const signInWithGithub = async () => {
    try {
        const user: UserCredential = await signInWithPopup(auth, githubProvider);
        logger.infos(user);
        // Apple authentication requires additional setup, check Firebase documentation
        // https://firebase.google.com/docs/auth/web/apple
    } catch (error) {
        logger.errors('Apple authentication failed:' + error);
        // Handle authentication error
    }
};

export const signInWithApple = async () => {
    try {
        // Apple authentication requires additional setup, check Firebase documentation
        // https://firebase.google.com/docs/auth/web/apple
    } catch (error) {
        logger.errors('Apple authentication failed:' + error);
        // Handle authentication error
    }
};

